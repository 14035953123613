<template>
  <ModalsConfirm :show="show">
    <div slot="actionQuestion">
      <img
        class="mx-auto"
        src="@/assets/images/phone-icon-cl.png"
        alt="phone-icon-cl"
      />
    </div>
    <div slot="actionText" class="mt-6">
      <h3 class="text-xl font-proximaMedium">
        {{ $t("phone_otp_by_sms_title") }}
      </h3>
      <p class="mt-2 text-sm">
        {{ $t("phone_otp_by_sms_info_text") }}
        <span class="text-primary font-proximaMedium">{{ phoneNumber }}</span>
      </p>
      <FormError :errors="errorForm" />
      <FormInfo v-if="otpInfo" :infos="otpInfo" />
      <FormInfo v-else :infos="infOtp" />
      <div
        class="mt-2 mb-2 flex justify-center items-center"
        style="flex-direction: column"
      >
        <InputPin @blurred="inputPinVal" class="input-otp" />
        <div class="flex items-end cursor-pointer mt-4" @click="resetOtp">
          <img src="@/assets/images/refresh-icon.png" alt="refresh-icon" />
          <span class="ml-1">Resend code</span>
        </div>
      </div>
      <Loader class="mt-4 flex justify-center" v-if="showLoader" />
    </div>
    <BorderedButton
      slot="leftBtn"
      class="md:mr-1"
      :title="$t('global_cancel')"
      borderColor="border-mediumGrey"
      textCl="text-darkGrey"
      paddingX="px-8"
      width="w-full"
      @click.native="closeModal"
    />
    <SubmitButton
      slot="rightBtn"
      class="md:ml-1"
      :title="$t('global_confirm')"
      background="bg-success"
      paddingX="px-8"
      width="w-full"
      @click.native="submitForm"
    />
  </ModalsConfirm>
</template>

<script>
import SubmitButton from "../buttons/SubmitButton.vue";
import InputPin from "../forms/InputPin.vue";
import Loader from "../loader/Loader.vue";
import FormError from "../warning/FormError.vue";
import FormInfo from "../warning/FormInfo.vue";
import ModalsConfirm from "../modals/ModalsConfirm.vue";
import BorderedButton from "../buttons/BorderedButton.vue";

import { loginVerifyOtp, loginSendOtp } from "../../services/smsService";
import { getProgress } from "../../services/commonService";

import { mapActions } from "vuex";

export default {
  props: ["phoneNumber", "infOtp", "show"],
  components: {
    SubmitButton,
    InputPin,
    Loader,
    FormError,
    FormInfo,
    ModalsConfirm,
    BorderedButton,
  },
  data() {
    return {
      pinNumber: "",
      showLoader: false,
      otpInfo: "",
      progress: "",
    };
  },
  computed: {
    errorForm() {
      return this.$store.state.errorForm;
    },
  },
  async created() {},

  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
    }),
    async submitForm() {
      this.$store.commit("errorForm", "");
      this.showLoader = true;
      if (!this.pinNumber) {
        this.$store.commit("errorForm", "Pin number required");
        this.pinReq = false;
      } else {
        try {
          const phone_number = "%2B" + this.phoneNumber.slice(1);
          await loginVerifyOtp(phone_number, this.pinNumber).then(
            async (result) => {
              this.showLoader = false;
              this.signInAfterLogin(result.data);
              const { data: progress } = await getProgress();
              //console.log(progress);
              this.progress = progress.progress;
              const role = localStorage.getItem("role");
              role == 2
                ? this.$router.push("/guestpetoverview")
                : role == 4 || role == 5 //sp + vet
                ? this.$router.push("/sppassivedashboard/new")
                : this.progress == "0" && role != 2
                ? this.$router.push("/onboardingdashboard")
                : this.$router.push("/petoverview/news");
            }
          );
        } catch (error) {
          this.showLoader = false;
          this.$store.commit("errorForm", "Incorrect PIN. Try again");
        }
      }
    },

    inputPinVal(val) {
      this.pinNumber = val;
    },
    async resetOtp() {
      const { data: otp } = await loginSendOtp(this.phoneNumber, "EN");
      this.otpInfo = otp.message;
      console.log(this.otpInfo);

      //const phone_number = "%2B" + this.phoneNumber.slice(1);

      console.log("9999999999999s");
      this.inputPinVal("");
    },
    closeModal() {
      this.$store.commit("errorForm", "");
      this.showLoader = false;
      this.$emit("closeOTPVerification");
    },
  },
};
</script>

<style>
.input-otp {
  margin: 0 5px 0 0;
}
input#password-input {
  width: max-content !important;
  letter-spacing: 13.6px !important;
}
</style>
