<template>
  <div class="my-6 lg:my-0 flex flex-col md:flex-row items-start">
    <SectionLeft class="hidden lg:block" />
    <div class="h-full md:h-screen w-full lg:w-6/12 px-4 lg:pr-32">
      <CustRoleSection />
      <img
        class="h-28 mt-8 md:mt-0 mb-4 md:mb-8 lg:hidden m-auto"
        src="@/assets/images/dataservice-logo.svg"
        alt="dataservice-logo"
      />
    </div>
  </div>
</template>

<script>
import SectionLeft from "../components/SectionLeft.vue";
import CustRoleSection from "../components/join/CustRoleSection.vue";
import DataserviceLogo from "../components/DataserviceLogo.vue";

export default {
  components: {
    SectionLeft,
    CustRoleSection,
    DataserviceLogo,
  },
  computed: {
    errorForm() {
      return this.$store.state.errorForm;
    },
  },

  mounted() {
    this.$store.commit("errorForm", "");

    console.log("++++ ", this.$route.query.referral);
    if (this.$route.query.referral) {
      console.log("referral", this.$route.query.referral);
      localStorage.setItem("referral", this.$route.query.referral);
    } else {
      localStorage.setItem("referral", "");
    }
  },
};
</script>
