<template>
  <div class="w-10/12 lg:w-9/12">
    <div
      class="
        px-4
        lg:px-8
        py-6
        my-3
        bg-white
        border border-transparent
        hover:border-blue
        rounded-md
        drop-shadow
        transition-all
        flex
        items-center
        justify-between
        cursor-pointer
      "
      @mouseover="upHere = true"
      @mouseleave="upHere = false"
    >
      <div class="flex items-center font-proximaMedium">
        <img
          class="mr-5 h-12"
          :src="require(`@/assets/images/${img}.png`)"
          alt="icon"
        />
        {{ title }}
      </div>
      <ArrowRight width="20px" height="20px" v-if="upHere" />
    </div>
  </div>
</template>

<script>
import ArrowRight from "../icons/ArrowRight.vue";

export default {
  props: ["title", "img", "route"],
  components: {
    ArrowRight,
  },
  data() {
    return {
      upHere: false,
    };
  },
};
</script>

<style>
</style>