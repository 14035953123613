<template>
  <div class="h-full">
    <div
      class="w-full hidden md:block lg:hidden flex justify-center items-center"
    >
      <img
        class="h-72 m-auto"
        src="@/assets/images/perpetual-logo.png"
        alt="perpetual-logo"
      />
    </div>
    <div class="md:flex justify-center lg:justify-end hidden">
      <div
        class="pt-6 md:pt-12 text-base text-left md:text-right font-proximaLight"
      >
        {{ $t("join_us_sign_in_text")
        }}<span
          class="pl-1 text-right text-blue font-proximaMedium cursor-pointer transition-all hover:underline hover:decoration-solid hover:text-primary"
          @click="showModal = true"
          >{{ $t("join_us_Sign_in_link") }}</span
        >
      </div>
    </div>
    <div class="md:h-2/5 lg:h-2/3 md:hidden flex justify-center items-center">
      <img
        class="w-6/12 md:w-10/12 lg:w-9/12"
        src="@/assets/images/perpetual-platform.svg"
        alt="perpetual-logo"
      />
    </div>
    <div
      class="md:h-3/6 lg:h-4/6 mt-6 md:mt-20 flex flex-col justify-start items-end"
    >
      <div class="w-full flex flex-col items-center lg:items-end">
        <h3
          class="w-10/12 lg:w-9/12 md:w-10/12 pt-4 md:pt-0 text-2xl text-center md:text-left font-proximaMedium"
        >
          {{ $t("join_us_title") }}
        </h3>
        <div
          class="w-full mt-2 flex flex-col justify-start md:justify-end items-center lg:items-end"
        >
          <CustRoleBtn
            :title="$t('join_us_profile_pet_owner')"
            img="petowner-icon"
            @click.native="routeTo('PrimaryRegistration', 1)"
          />
          <!--   <CustRoleBtn
            :title="$t('join_us_profile_pet_service')"
            img="petservice-icon"
            @click.native="routeTo('ServiceType', 4)"
          /> -->
          <!--  <CustRoleBtn
            :title="$t('join_us_profile_veterinarian')"
            img="veterinarian-icon"
            @click.native="routeTo('ServiceType', 5)"
          /> -->
          <!--  <CustRoleBtn
            :title="$t('cust_role_section_non_profit')"
            img="nonprofit-icon"
            @click.native="routeTo('PrimaryRegistration', 6)"
          /> -->
          <CustRoleBtn
            :title="$t('join_us_profile_dog_breeder')"
            img="breeder-icon"
            @click.native="routeTo('PrimaryRegistration', 3)"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center md:justify-end">
      <div
        class="pt-6 md:pt-12 text-base md:text-sm text-left md:text-right font-proximaLight md:hidden"
      >
        {{ $t("join_us_sign_in_text")
        }}<span
          class="pl-1 text-right text-blue font-proximaMedium cursor-pointer"
          @click="showModal = true"
          >{{ $t("join_us_Sign_in_link") }}</span
        >
      </div>
    </div>
    <ModalsLogin
      :show="showModal"
      @close="showModal = false"
      @closeModalsOnSuccess="closeModalsOnSuccess"
    >
      <template #header>
        <h3>custom header</h3>
      </template>
    </ModalsLogin>
  </div>
</template>

<script>
import CustRoleBtn from "./CustRoleBtn.vue";
import ModalsLogin from "../modals/ModalsLogin.vue";

export default {
  components: {
    CustRoleBtn,
    ModalsLogin,
  },
  data() {
    return {
      showModal: false,
    };
  },
  created() {
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    localStorage.removeItem("firstpet");
    localStorage.removeItem("sharingRulesPreferences");
    localStorage.removeItem("ruleID");
    localStorage.removeItem("referral");
  },
  methods: {
    routeTo(d, v) {
      console.log("----------", v);
      this.$router.push({ name: d, params: { role: v } });
      this.$store.commit("regType", v);
    },
    closeModalsOnSuccess(i) {
      this.showModal = i;
    },
  },
};
</script>

<style></style>
