<template>
  <div>
    <Transition name="modal">
      <div v-if="show" class="modal-mask">
        <div class="modal-wrapper">
          <div
            class="modal-container md:w-10/12 lg:w-6/12 flex flex-col justify-center items-center relative"
          >
            <div
              class="absolute top-3 right-6 text-lg font-proximaSemibold cursor-pointer"
              @click="closeLoginModal"
            >
              ✖
            </div>
            <div
              class="p-5 modal-header flex justify-center items-center bg-primary-20 rounded-full"
            >
              <img
                rel="prefetch"
                src="@/assets/images/login-icon.png"
                alt="login-icon"
                class="h-12"
              />
            </div>

            <div
              class="w-10/12 md:w-6/12 modal-body mt-5 mb-0 font-proximaMedium flex flex-col justify-center items-center"
            >
              <FormError :errors="errorForm" />

              <input
                type="email"
                placeholder="Email Address or Phone Number"
                v-model="email"
                class="w-full rounded-md px-3 py-3 my-1 text-sm bg-greyishWhite placeholder-mediumGrey font-proximaMedium"
                @mouseleave="addplustophonenumber"
              />
              <SubmitButton
                class="-mt-0"
                :title="$t('join_us_Sign_in_link')"
                background="bg-primary"
                paddingX="px-5"
                width="w-full"
                @click.native="sendMagicLinkByEmail"
              />
              <Loader class="mt-2 flex justify-center" v-if="showLoaderComp" />
            </div>
            <div class="modal-footer w-10/12 md:w-6/12">
              <div
                class="mt-3 w-full flex items-center justify-center font-proximaLight"
              >
                <div class="w-full border border-lightGrey"></div>
                <span class="px-4">or</span>
                <div class="w-full border border-lightGrey"></div>
              </div>
              <div class="mt-3 flex items-center justify-center">
                <a :href="this.googleLink">
                  <img
                    src="@/assets/images/google-icon.png"
                    alt="google-icon"
                    class="mx-2 cursor-pointer"
                  />
                </a>

                <!--  <a :href="this.twitterLink">
                <img
                  src="@/assets/images/twitter-icon.png"
                  alt="twitter-icon"
                  class="mx-2 cursor-pointer"
                />
              </a> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </Transition>
    <ModalsOTPVerification
      :show="modalsOTPVerification"
      @closeOTPVerification="closeModals"
      :phoneNumber="this.phoneNumber"
      :infOtp="this.infOtp"
    />
  </div>
</template>

<script>
import TrashIcon from "../icons/TrashIcon.vue";
import CheckIcon from "../icons/CheckIcon.vue";
import SubmitButton from "../buttons/SubmitButton.vue";
import BorderedButton from "../buttons/BorderedButton.vue";
import FormError from "../warning/FormError.vue";
import FormInfo from "../warning/FormInfo.vue";
import ModalsOTPVerification from "../modals/ModalsOTPVerification.vue";
import Loader from "../loader/Loader.vue";

import { apiUrl } from "../../config.json";
import { sendLoginEmail } from "../../services/emailService";
import { loginSendOtp } from "../../services/smsService";
import { errorMixin } from "../../errorMixin.js";

import { mapActions } from "vuex";

export default {
  //mixins: [errorMixin],
  name: "signIn",
  name: "signInAfterLogin",
  props: {
    show: Boolean,
  },
  components: {
    TrashIcon,
    CheckIcon,
    SubmitButton,
    BorderedButton,
    FormError,
    FormInfo,
    ModalsOTPVerification,
    Loader,
  },
  data() {
    return {
      email: "",
      twitterLink: `${apiUrl}authentication/login/twitter/?role=1`,
      googleLink: `${apiUrl}authentication/login/google/?role=1`,
      emailReq: false,
      phoneReq: false,
      infOtp: "",
      phoneNumber: "",
      modalsOTPVerification: false,
    };
  },
  computed: {
    errorForm() {
      return this.$store.state.errorForm;
    },
    showLoaderComp() {
      return this.$store.state.showLoaderState;
    },
  },
  created() {
    this.$store.commit("errorForm", "");
  },
  methods: {
    ...mapActions({
      signIn: "auth/signIn",
      signInAfterLogin: "auth/signInAfterLogin",
    }),
    addplustophonenumber() {
      if (this.email.charAt(0) != "+") {
        if (this.validPhone(this.email)) {
          this.email = "+" + this.email;
        }
      }
    },
    async sendMagicLinkByEmail() {
      this.$store.commit("errorForm", "");

      if (!this.email) {
        this.$store.commit(
          "errorForm",
          "Email Address or Phone Number required"
        );
      }

      if (!this.validEmail(this.email)) {
        if (!this.validPhone(this.email))
          this.$store.commit(
            "errorForm",
            "Valid Email Address or Phone Number required."
          );
      }
      if (this.validEmail(this.email.toLowerCase())) {
        this.emailReq = true;
      }
      if (this.validPhone(this.email)) {
        this.phoneReq = true;
      }

      if (this.emailReq == true) {
        this.$store.commit("showLoader", true);

        await sendLoginEmail(this.email.toLowerCase(), "EN");
        //window.location.href = "mailto:" + this.email_address;
        this.$store.commit("showLoader", false);
        this.$emit("closeModalsOnSuccess", false);
      } else if (this.phoneReq) {
        this.$store.commit("showLoader", true);

        await loginSendOtp(this.email.toLowerCase(), "EN").then((result) => {
          this.$store.commit("showLoader", false);
          this.infOtp = result.data.message;

          //let newotp = this.infOtp.slice(-4);

          this.phoneNumber = this.email;
          this.modalsOTPVerification = true;
          this.$emit("closeModalsOnSuccess", false);
        });
      }
    },

    closeModals() {
      this.closeLoginModal();
      this.modalsOTPVerification = false;
    },

    closeLoginModal() {
      this.$store.commit("errorForm", "");
      this.email = "";
      this.$store.commit("showLoader", false);
      this.$emit("close");
    },
    validEmail(email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    validPhone(phone) {
      var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{0,7}$/;

      return re.test(phone);
    },
  },
};
</script>

<style scoped lang="scss">
ul {
  list-style: none; /* Remove default bullets */
}

ul li::before {
  content: "\2022"; /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #0ac0cb; /* Change the color */
  font-weight: bold; /* If you want it to be bold */
  display: inline-block; /* Needed to add space between the bullet and the text */
  width: 1em; /* Also needed for space (tweak if needed) */
  margin-left: -1em; /* Also needed for space (tweak if needed) */
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  // width: 600px;
  margin: 0px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
</style>
