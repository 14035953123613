<template>
  <svg
    :width="width"
    :height="height"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5892 3.57709C10.2637 3.25165 9.7361 3.25165 9.41066 3.57709C9.08523 3.90252 9.08523 4.43016 9.41066 4.7556L13.8214 9.16634H4.16659C3.70635 9.16634 3.33325 9.53944 3.33325 9.99968C3.33325 10.4599 3.70635 10.833 4.16659 10.833H13.8214L9.41066 15.2438C9.08523 15.5692 9.08523 16.0968 9.41066 16.4223C9.7361 16.7477 10.2637 16.7477 10.5892 16.4223L16.4225 10.5889C16.7479 10.2635 16.7479 9.73586 16.4225 9.41042L10.5892 3.57709Z"
      :fill="iconColor"
    />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 18,
    },
    height: {
      type: [Number, String],
      default: 18,
    },
    iconColor: {
      type: String,
      default: "#19C0CC",
    },
  },
};
</script>